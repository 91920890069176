
<script setup>
import { ref, computed } from 'vue'
import HelloWorld from './components/HelloWorld.vue';
import ArtPortfolio from './components/ArtPortfolio.vue';

console.log('can anyone hear me');

const routes = {
  '/': HelloWorld,
  'art': ArtPortfolio
}

const currentPath = ref(window.location.pathname);


window.addEventListener('hashchange', () => {
  currentPath.value = window.location.pathname
})

// eslint-disable-next-line
const currentView = computed(() => {
  return routes[currentPath.value.slice(1) || '/'] 
})
</script>

<template>
  <component :is="currentView" />
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');

#app {
  min-height: 98vh;
  font-family: 'Space Grotesk', Michroma, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  opacity: 1;
  background-image: radial-gradient(var(--rosepink) 0.45px, var(--inkblue) 0.45px);
  background-size: 9px 9px;

  display:flex;
  justify-content:center;
  align-items:center;
}


</style>
